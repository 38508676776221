<template>
    <div class="eq-financing">
        <!-- <div class="z-0 absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#0e192d] to-transparent"></div> -->
        <div class="relative space-y-8 md:space-y-14 md:max-w-xl">
            <h2 class="text-white font-bold text-3xl md:text-4xl">Equipment Financing</h2>
            <h4 class="text-white md:text-xl">
                Unlock the potential of your business with one of M1 Financial's equipment finance vendor programs. Our approach eliminates the barriers to essential machinery and technology by offering tailored financing for equipment that spreads costs over time.
            </h4>
            <div class="group">
                <Link :href="route('simple application')">
                    <div class="bg-red-primary p-4 max-w-sm text-center text-white font-medium rounded-md shadow-md border border-transparent group-hover:border-white duration-300">APPLY FOR LEASING</div>
                </Link>
            </div>
        </div>
    </div>
</template>
<style scoped>
.eq-financing {
    @apply h-full relative bg-[url(https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1721318650/M1Financial/Home/M1F-Tech-Splash.webp)] bg-cover px-10 lg:px-20  flex flex-col justify-center;
}
@media screen and (min-width: 2300px) {
    .eq-financing {
        background-position-y: -4rem;
    }
}
</style>
