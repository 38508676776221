<template>
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100">
        <path
            d="M20.18 60.31a4.58 4.58 0 1 0 0 9.16 4.58 4.58 0 0 0 4.58-4.58c0-1.51-.74-2.83-1.86-3.66l3.35-6.49h11.92l5.96 10.32-6.25 10.82c-.18.31-.18.69 0 1l6.54 11.32c.18.31.51.5.87.5h13.07c.36 0 .69-.19.87-.5l6.25-10.82h9.9a4.577 4.577 0 0 0 4.46 3.58c1.22 0 2.37-.48 3.24-1.34s1.34-2.02 1.34-3.24h-1 1c0-1.22-.48-2.37-1.34-3.24-.62-.62-1.4-1.03-2.24-1.22V60.51c.84-.19 1.62-.6 2.24-1.23.86-.87 1.34-2.02 1.34-3.24h-1 1c0-1.22-.48-2.37-1.34-3.24a4.553 4.553 0 0 0-3.24-1.34c-2.53 0-4.58 2.06-4.58 4.58 0 2.18 1.54 4 3.58 4.46v11.41a4.581 4.581 0 0 0-3.46 3.46h-9.9l-6.25-10.82c-.18-.31-.51-.5-.87-.5H45.87l-5.96-10.32 5.96-10.32h12.49c.35 0 .68-.19.86-.49l4.17-6.99h12.16c.36 0 .69-.19.87-.5l6.54-11.32c.18-.31.18-.69 0-1l-6.54-11.32c-.18-.31-.51-.5-.87-.5H62.48c-.36 0-.69.19-.87.5l-6.54 11.32c-.18.31-.18.69 0 1l6.41 11.1-3.69 6.19H45.81l-5.97-10.34 3.41-6.59c.34.08.7.14 1.06.14a4.58 4.58 0 1 0 0-9.16 4.58 4.58 0 0 0-4.58 4.58c0 1.44.68 2.71 1.72 3.55l-3.36 6.5H25.63c-.36 0-.69.19-.87.5L18.22 41.9c-.18.31-.18.69 0 1l6.27 10.86-3.42 6.62c-.29-.06-.59-.09-.89-.09Zm57.07-4.27c0-.69.27-1.34.75-1.83s1.14-.76 1.83-.76c.69 0 1.34.27 1.82.75.49.49.76 1.13.76 1.82 0 .69-.27 1.34-.75 1.83s-1.13.76-1.83.76c-1.42 0-2.58-1.16-2.58-2.58Zm2.58 17.76c.69 0 1.34.27 1.82.75.49.49.76 1.13.76 1.82 0 .69-.27 1.34-.75 1.83s-1.13.76-1.83.76c-1.42 0-2.58-1.16-2.58-2.58 0-1.42 1.16-2.58 2.58-2.58Zm-16.1 2.58L57.77 86.7H45.85l-5.96-10.32 5.96-10.32h11.92l5.96 10.32Zm-.69-63.06h11.91l5.96 10.32-5.96 10.32H63.04l-5.96-10.32 5.96-10.32ZM44.3 17.48c1.42 0 2.58 1.16 2.58 2.58s-1.16 2.58-2.58 2.58-2.58-1.16-2.58-2.58 1.16-2.58 2.58-2.58ZM26.2 32.11h11.91l5.96 10.32-5.96 10.32H26.2l-5.96-10.32 5.96-10.32Zm-3.45 32.78c0 1.42-1.16 2.58-2.58 2.58s-2.58-1.16-2.58-2.58 1.16-2.58 2.58-2.58c1.42 0 2.58 1.16 2.58 2.58Z"
            class="cls-1" />
    </svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
