<template>
    <div class="h-full relative bg-[url(https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1721337481/M1Financial/Home/M1F-Vendor-Splash.webp)] bg-cover xl:bg-center px-10 lg:px-20  flex flex-col justify-center">
        <div class="relative space-y-8 md:space-y-14 md:max-w-xl">
            <h2 class="text-white font-bold text-3xl md:text-4xl">Equipment Leasing</h2>
            <h4 class="text-white md:text-xl">
                Choose flexibility and cost-efficiency with customized equipment leasing solutions. Our equipment leasing company provides the agility your business needs to adapt and thrive without the commitment of ownership.
            </h4>
            <div class="group">
                <Link :href="route('simple application')">
                    <div class="bg-red-primary p-4 max-w-sm text-center text-white font-medium rounded-md shadow-md border border-transparent group-hover:border-white duration-300">APPLY FOR LEASING</div>
                </Link>
            </div>
        </div>
    </div>
</template>
