<template>
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100">
        <path
            d="M91.55 66.54H87.1V22.41c0-.55-.45-1-1-1H13.9c-.55 0-1 .45-1 1v44.13H8.45c-.55 0-1 .45-1 1 0 6.09 3.84 11.04 8.56 11.04H84c4.72 0 8.55-4.95 8.55-11.04 0-.55-.45-1-1-1ZM14.9 23.41h70.2v43.13H60.17c-.85 0-1.52.74-1.52 1.69v.07H41.34v-.07c0-.95-.67-1.69-1.52-1.69H14.9V23.41ZM84 76.58H16.01c-3.37 0-6.16-3.53-6.52-8.04h29.87c.05.39.19.75.42 1.05.34.45.84.71 1.37.71h17.71c.92 0 1.66-.75 1.78-1.76h29.87c-.36 4.52-3.15 8.04-6.51 8.04Z"
            class="cls-1" />
    </svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
