<template>
    <div class="h-full relative bg-[url(https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1721337248/M1Financial/Home/M1F-Business-Splash.webp)] bg-cover px-10 lg:px-20  flex flex-col justify-center">
        <!-- <div class="z-0 absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#8ea7ae] to-transparent"></div> -->
        <div class="relative space-y-6 md:space-y-10 md:max-w-2xl">
            <h2 class="font-bold text-3xl md:text-4xl uppercase text-white">Increase Equipment Sales</h2>
            <h4 class="text-white text-xl lg:text-2xl font-medium">
                Make equipment more affordable and increase sales by utilizing our flexible financing options and lease programs, including deferred payments.
            </h4>
            <div class="group">
                <Link :href="route('industries')">
                    <div class="border border-white p-4 max-w-sm text-center text-white font-medium rounded-md shadow-sm group-hover:shadow-xl group-hover:bg-white group-hover:text-gray-primary duration-300">TARGET INDUSTRIES WE SERVE</div>
                </Link>
            </div>
        </div>
    </div>
</template>
